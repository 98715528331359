/** @format */

import React from "react";
import Header from "./Header";
import { schools, grad_hat } from "../assets/icons";
import HeroImage from "../assets/header.png";

const Hero = ({ onClick }) => {
  return (
    <div className="bg-[#F8F8F8] w-full pb-10 px-5 lg:px-0 lg:pb-0 pt-10 overflow-hidden">
      <div className="w-full  lg:w-3/4 lg:mx-auto text-center">
        <Header />
        <div className="hero_tag w-4/6 text-center lg:w-[215px] p-2 rounded-[96px] mx-auto my-5 font-[600]">
          Connecting teachers to schools
        </div>

        <div>
          <h1 className="font-[700] text-[24px] lg:text-[48px] text-[#121212] leading-[30px] lg:leading-[55.2px]">
            Connecting Passionate <br className="lg:hidden" /> And Qualified{" "}
            <br className="hidden lg:flex" /> Teaching{" "}
            <br className="lg:hidden" /> Talents To Schools.{" "}
          </h1>
          <p className="text-[#636363] font-[400] text-[16px] lg:text-[18px] my-5 px-5 lg:px-0lg:my-10 leading-[22px] lg:leading-[36px]">
            Rotacude is matching dedicated teachers with schools
            <br className="hidden lg:flex" /> in need. Find specialised teachers
            within minutes.{" "}
          </p>
        </div>

        <div className="lg:flex flex-wrap items-center justify-center  w-full  lg:w-2/3 xl:w-1/3 lg:mx-auto">
          <a href="/#joinUs" className="flex items-center w-full lg:w-1/2 ">
            <div
              className="bg-[#191970] rounded-[9px] cursor-pointer text-white p-3 lg:p-2 px-4 mx-1 flex justify-center items-center w-full"
              onClick={() => onClick(0)}
            >
              <img src={grad_hat} alt="for teachers" className="mr-2" />
              <span className=""> For Teachers</span>
            </div>
          </a>
          <a
            href="/#joinUs"
            className="flex items-center w-full lg:w-1/2 mt-3 lg:mt-0"
          >
            <div
              className="bg-[#191970] rounded-[9px] cursor-pointer text-white p-3 lg:p-2 px-4 mx-1 flex justify-center items-center w-full"
              onClick={() => onClick(1)}
            >
              <img src={schools} alt="for schools" className="mr-2" />
              <span> For Schools</span>
            </div>
          </a>
        </div>

        <div className="relative flex justify-center h-[150px] lg:h-[368px] w-full  lg:w-full mx-auto mt-12">
          <div className="lg:absolute mx-auto mt-3 lg:-bottom-16 w-full md:w-4/5 lg:w-full">
            <img
              src={HeroImage}
              alt="hero-image"
              className="h-[200px] lg:h-[488px] lg:w-full object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
