/** @format */

import React from "react";
import Teacher from "../assets/teacher.jpeg";
import School from "../assets/school.png";

import {
  hourglass,
  eye,
  book,
  sort,
  contentcut,
  groupadd,
} from "../assets/icons";

const Benefits = () => {
  return (
    <div className="bg-[#111111] flex flex-col justify-center  py-14 pb-20 lg:pb-60 lg:pt-40">
      <h1 className="text-[24px] lg:text-[52px] font-[700] text-[#F8F8F8] text-center px-2 lg:px-0">
        Here’s How We Help you{" "}
      </h1>
      <p className="text-[#fff] text-[16px] lg:text-[20px] text-center font-[500] py-2 px-6 lg:px-0">
        Rotacude is on a mission to make teaching profitable again by connecting
        teachers to schools where they matter.{" "}
      </p>

      <div className="w-full lg:w-10/12  mx-auto  mt-12 lg:mt-24">
        <div className="w-full flex flex-wrap lg:flex-nowrap items-start justify-between">
          <div className="w-full lg:w-1/2 mx-5 lg:mx-0">
            <span
              className="font-[700] px-3 pt-2.5 pb-1.5 rounded-[100px] bg-[#F8F8F8] text-[12px] lg:text-[18px] text-[#111111] "
              style={{ fontFamily: "grifter" }}
            >
              FOR TEACHERS
            </span>
            <h1 className="font-[700]  text-[20px] lg:text-[48px] text-[#fff] my-3 lg:my-5">
              Made for Teachers
            </h1>
            <p className="text-[16px] lg:text-[20px] text-[#F8F8F8] font-[400] my-3 mb-6 lg:my-6 w-full">
              Teaching can be rewarding and we offer just the right support to
              make it worth it.
            </p>

            <img
              src={Teacher}
              alt="teacher's image"
              className="w-[580px]  h-[229px] lg:h-[342px] object-cover rounded-[24px]"
            />
          </div>
          <div className="w-full lg:w-1/2 lg:ml-10 mt-10 lg:mt-0 mx-5 lg:mx-0">
            <div className="w-full flex flex-wrap lg:flex-nowrap items-center ">
              <div className="lg:w-1/2 rounded-[16px] bg-[#FFFFFF] shadow-md px-5 py-5 lg:pt-5 lg:pb-20 lg:h-[317px] lg:mr-5">
                <img
                  src={eye}
                  alt="eye"
                  className="w-[32px] h-[32px] lg:w-[48px] lg:h-[48px]"
                />
                <h1 className="text-[20px] lg:text-[24px] font-[600] text-[#121212] my-2 lg:my-4 lg:mt-10">
                  Increased Visibility
                </h1>
                <p className="text-[#71717A] text-[16px] lg:text-[18px] font-[400]">
                  Showcase your qualifications and experience to a wider network
                  of schools in Nigeria.
                </p>
              </div>
              <div className="lg:w-1/2 rounded-[16px] bg-[#FFFFFF] shadow-md px-5 py-5 lg:pt-5 lg:pb-20 lg:h-[317px] mt-5 lg:mt-0">
                <img
                  src={sort}
                  alt="eye"
                  className="w-[32px] h-[32px] lg:w-[48px] lg:h-[48px]"
                />
                <h1 className="text-[20px] lg:text-[24px] font-[600] text-[#121212] my-2 lg:my-4 lg:mt-10">
                  Find the Right Match{" "}
                </h1>
                <p className="text-[#71717A] text-[16px] lg:text-[18px] font-[400]">
                  Our algorithm matches you to schools that fit your preference
                  and experience.
                </p>
              </div>{" "}
            </div>
            <div className="w-full rounded-[16px] bg-[#FFFFFF] shadow-md px-5 py-5  mt-5">
              <img
                src={book}
                alt="eye"
                className="w-[32px] h-[32px] lg:w-[48px] lg:h-[48px]"
              />
              <h1 className="text-[20px] lg:text-[24px] font-[700] text-[#121212] my-2 lg:my-4">
                Access Resources{" "}
              </h1>
              <p className="text-[#71717A] text-[16px] lg:text-[18px] font-[400]">
                Our resource library helps you get better as a teacher, making
                you more valuable with every turn.
              </p>
            </div>{" "}
          </div>
        </div>

        <div className="w-full mt-24 flex flex-wrap lg:flex-nowrap lg:mx-0 items-start justify-between">
          <div className="lg:hidden lg:w-1/2 mx-5 lg:mx-0 mt-5 lg:mt-0">
            <span
              className="font-[700] px-3 pt-2.5 pb-1.5 rounded-[100px] bg-[#F8F8F8] text-[12px] lg:text-[18px] text-[#111111] "
              style={{ fontFamily: "grifter" }}
            >
              FOR SCHOOLS
            </span>
            <h1 className="font-[700]  text-[20px] lg:text-[48px] text-[#fff] my-3 lg:my-5">
              Empowering Schools{" "}
            </h1>
            <p className="text-[16px] lg:text-[20px] text-[#F8F8F8] font-[400] my-3 mb-6 lg:my-6 w-full">
              Rotacude keeps schools fully staffed so students never have to go
              without the right teachers.
            </p>

            <img
              src={School}
              alt="teacher's image"
              className="w-[580px]  h-[200px] lg:h-[342px] object-cover rounded-[24px]"
            />
          </div>
          <div className="lg:w-1/2 lg:mr-10 mx-5 mt-10 lg:mt-0 lg:mx-0">
            <div className="w-full flex flex-wrap lg:flex-nowrap items-center ">
              <div className="lg:w-1/2 rounded-[16px] bg-[#FFFFFF] shadow-md px-5 py-5 lg:pt-5 lg:pb-20 lg:h-[317px] lg:mr-5">
                <img
                  src={groupadd}
                  alt="eye"
                  className="w-[32px] h-[32px] lg:w-[48px] lg:h-[48px]"
                />
                <h1 className="text-[20px] lg:text-[24px] font-[600] text-[#121212] my-2 lg:my-4  lg:mt-10">
                  Streamline Hiring{" "}
                </h1>
                <p className="text-[#71717A] text-[16px] lg:text-[18px] font-[400]">
                  Efficiently recruit qualified teachers through our matching
                  algorithm.
                </p>
              </div>
              <div className="lg:w-1/2 rounded-[16px] bg-[#FFFFFF] shadow-md px-5 py-5 lg:pt-5 lg:pb-20  lg:h-[317px] mt-5 lg:mt-0">
                <img
                  src={contentcut}
                  alt="eye"
                  className="w-[32px] h-[32px] lg:w-[48px] lg:h-[48px]"
                />
                <h1 className="text-[20px] lg:text-[24px] font-[600] text-[#121212] my-2 lg:my-4 lg:mt-10">
                  Reduce Costs{" "}
                </h1>
                <p className="text-[#71717A] text-[16px] lg:text-[18px] font-[400]">
                  Save time and resources spent on putting up vacancies and
                  interviewing teachers.{" "}
                </p>
              </div>{" "}
            </div>
            <div className="w-full rounded-[16px] bg-[#FFFFFF] shadow-md px-5 py-5  mt-5">
              <img
                src={hourglass}
                alt="eye"
                className="w-[32px] h-[32px] lg:w-[48px] lg:h-[48px]"
              />
              <h1 className="text-[20px] lg:text-[24px] font-[600] text-[#121212] my-2 lg:my-4">
                Access To The Right Talents{" "}
              </h1>
              <p className="text-[#71717A] text-[16px] lg:text-[18px] font-[400]">
                Our internal vetting system filters talents so you are only
                matched with the most experienced and safest teachers.
              </p>
            </div>{" "}
          </div>
          <div className="hidden lg:block flex-col w-full lg:w-1/2 mx-5 lg:mx-0">
            <span
              className="font-[700] px-3 pt-2.5 pb-1.5 rounded-[100px] bg-[#F8F8F8]  text-[12px] lg:text-[18px] text-[#111111]"
              style={{ fontFamily: "grifter" }}
            >
              FOR SCHOOLS
            </span>
            <h1 className="font-[700]  text-[30px] lg:text-[48px] text-[#fff] mt-5">
              Empowering Schools{" "}
            </h1>
            <p className="text-[16px] lg:text-[20px] text-[#F8F8F8] font-[400] my-3 mb-6 lg:-6 w-full">
              Rotacude keeps schools fully staffed so students never have to go
              without the right teachers.
            </p>

            <img
              src={School}
              alt="teacher's image"
              className="w-[580px]  h-[229px] lg:h-[342px] object-cover rounded-[24px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Benefits;
