/** @format */

export const FaqData = [
  {
    title: "What is Rotacude?",
    desc: "Rotacude is a comprehensive platform designed to streamline the hiring process for schools and facilitate teacher placements in quality educational institutions. Our platform utilizes advanced technology to match schools with qualified teachers efficiently",
  },
  {
    title: "How does Rotacude work?",
    desc: "Rotacude works by providing schools with a user-friendly interface to post job openings and specify their requirements. On the other hand, teachers can create profiles showcasing their qualifications, experience, and preferences. Our intelligent matching algorithm then connects schools with suitable candidates, simplifying the hiring process for both parties.",
  },
  {
    title: "What makes Rotacude different from traditional hiring methods?",
    desc: "Unlike traditional hiring methods, Rotacude offers a centralized platform where schools and teachers can connect seamlessly. Our platform saves time and resources by automating tasks such as job posting, candidate screening, and interview scheduling. Additionally, our focus on quality ensures that schools find the best-fit candidates for their vacancies.",
  },
  {
    title:
      "Can teachers update their profiles and preferences after registration?",
    desc: "Absolutely! Teachers can update their profiles and preferences at any time to reflect changes in qualifications, experience, or preferences. Keeping your profile up-to-date ensures that you receive relevant job opportunities and increases your chances of finding the perfect placement.",
  },
  {
    title: "How can I get started with Rotacude?",
    desc: "Getting started with Rotacude is easy! Simply sign up as a school or teacher on our website, complete your profile, and start exploring the available opportunities. Our team is also available to provide assistance and support throughout the process.",
  },
  {
    title: "What happens if a placement doesn't work out as expected?",
    desc: "In the rare event that a placement doesn't work out as expected, Rotacude offers support to both schools and teachers to find alternative solutions. Our team works closely with all parties involved to address any concerns and ensure a positive outcome for everyone",
  },
  {
    title: "How does Rotacude protect the privacy and security of user data?",
    desc: "At Rotacude, we prioritize the security and privacy of user data. We employ robust encryption protocols, access controls, and data anonymization techniques to safeguard sensitive information. Additionally, we comply with industry regulations such as GDPR and adhere to best practices in data protection.",
  },
];


export const NigerianStates = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "FCT - Abuja",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
];