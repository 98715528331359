/** @format */

import React from "react";
import Logo from "../assets/footerLogo.svg";
import { li, tw, fb, ig } from "../assets/icons";
import { Link } from "react-router-dom";

const Footer = () => {
  const socials = [
    {
      name: "Linkedin ",
      img: li,
    },
    {
      name: "Twitter ",
      img: tw,
    },
    {
      name: "Facebook",
      img: fb,
    },
    {
      name: "Instagram",
      img: ig,
    },
  ];
  return (
    <div className="w-full bg-[#231F20]">
      <div className="w-10/12 mx-auto  lg:flex flex-wrap lg:flex-nowrap items-start justify-around text-[#fff] py-5 lg:py-32">
        <img src={Logo} alt="logo" className="" />
        <div className="my-10 lg:my-0 hidden lg:flex flex-col">
          <h1 className="text-[18px] font-[700]  mb-3  ">Company</h1>
          {["About us", "FAQs"].map((item, index) => (
            <Link to={index === 0 ? "/about" : "/faq"}>
              <p
                key={index}
                className="text-[16px] font-[400]  mb-2 cursor-pointer"
              >
                {item}
              </p>{" "}
            </Link>
          ))}
        </div>

        <div className="my-5 lg:my-0 hidden lg:flex flex-col">
          <h1 className="text-[18px] font-[700]  mb-3  ">Use Cases</h1>
          {["For Teachers", "For Schools"].map((item, index) => (
            <Link to={index === 0 ? "/schools" : "/teachers"}>
              <p
                key={index}
                className="text-[16px] font-[400]  mb-2 cursor-pointer"
              >
                {item}
              </p>
            </Link>
          ))}
        </div>
        <div className="my-10 lg:my-0 hidden lg:flex flex-col">
          <h1 className="text-[18px] font-[700] mb-3 ">Social Media</h1>
          <div className="flex flex-wrap ">
            {socials.map((item, index) => (
              <a
                href={
                  index === 0
                    ? "https://www.linkedin.com/company/rotacude/"
                    : index === 1
                    ? "https://twitter.com/ROTACUDE_"
                    : index === 2
                    ? "https://www.facebook.com/profile.php?id=61559105555308"
                    : "https://www.instagram.com/rotacude_?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                }
                target="_blank"
              >
                <div
                  key={index}
                  className="w-full flex flex-wrap items-center mb-2 cursor-pointer"
                >
                  <img
                    src={item.img}
                    alt=""
                    className="w-[21px] h-[21px] mr-2.5"
                  />
                </div>
              </a>
            ))}
          </div>
          <p className="text-[16px] font-[400]  my-3 ">admin@rotacude.com</p>
          <p className="text-[16px] font-[400]  ">09072203493</p>
        </div>
        <div className="flex flex-wrap lg:hidden  justify-between items-start ">
          <div className="my-10 lg:my-0">
            <h1 className="text-[18px] font-[700]  mb-3  ">Company</h1>
            {["About us", "FAQs"].map((item, index) => (
              <Link to={index === 0 ? "/about" : "/faq"}>
                <p
                  key={index}
                  className="text-[16px] font-[400]  mb-2 cursor-pointer"
                >
                  {item}
                </p>{" "}
              </Link>
            ))}
          </div>

          <div className="my-10 lg:my-0">
            <h1 className="text-[18px] font-[700] mb-3 ">Social Media</h1>
            <div className="flex flex-wrap ">
              {socials.map((item, index) => (
                <a
                  href={
                    index === 0
                      ? "https://www.linkedin.com/company/rotacude/"
                      : index === 1
                      ? "https://twitter.com/ROTACUDE_"
                      : index === 2
                      ? "https://www.facebook.com/profile.php?id=61559105555308"
                      : "https://www.instagram.com/rotacude_?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                  }
                  target="_blank"
                >
                  <div
                    key={index}
                    className="w-full flex flex-wrap items-center mb-2 cursor-pointer"
                  >
                    <img
                      src={item.img}
                      alt=""
                      className="w-[21px] h-[21px] mr-2.5"
                    />
                  </div>
                </a>
              ))}
            </div>
            <p className="text-[16px] font-[400]  my-3 ">admin@rotacude.com</p>
            <p className="text-[16px] font-[400]  ">09072203493</p>
          </div>

          <div className="my-5 lg:my-0">
            <h1 className="text-[18px] font-[700]  mb-3  ">Use Cases</h1>
            {["For Teachers", "For Schools"].map((item, index) => (
              <Link to={index === 0 ? "/schools" : "/teachers"}>
                <p
                  key={index}
                  className="text-[16px] font-[400]  mb-2 cursor-pointer"
                >
                  {item}
                </p>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
