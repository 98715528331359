/** @format */

import React, { useState } from "react";
import Logo from "../assets/Logo.svg";
import { Link } from "react-router-dom";
import Ham from "../assets/icons/HAM.svg";
import Close from "../assets/icons/CANCEL.svg";
import VisibilitySensor from "react-visibility-sensor";
import { Spring } from "react-spring/renderprops";
import "../styles/index.css"

const Header = () => {
  const [hidden, setHidden] = useState(true);
  const [active, setActive] = useState(false);

  return (
    <div className="header_container flex justify-between items-center py-5 px-5 lg:px-10 w-full mx-auto bg-[#fff]">
      <div className="hidden lg:flex text-[#121212]  text-[14px] w-full items-center justify-between relative">
        <div className=" h-[20px] lg:h-[32px]">
          <Link to="/">
            <img
              src={Logo}
              alt="logo"
              className="object-contain w-full h-full"
            />
          </Link>
        </div>
        <div
          className="hidden lg:flex text-[#121212]  text-[14px] w-full lg:w-1/3 justify-evenly relative"
          style={{ fontWeight: "500" }}
        >
          {!hidden && (
            <div
              className="rounded-[8px] border-[1px] border-[#DADADA]  absolute  bg-[white] top-7 left-8"
              onMouseLeave={() => setHidden(true)}
            >
              <Link
                to="/schools"
                className="link text-[#121212] hover:text-[#191970] hover:bg-[#DADADA] hover:font-bold cursor-pointer focus:text-[#191970] font-bold py-3"
              >
                <p className="hover:bg-[#DADADA] p-3">For Schools</p>
              </Link>{" "}
              <Link
                to="/teachers"
                className="link text-[#121212] hover:text-[#191970] hover:font-bold cursor-pointer focus:text-[#191970] font-bold py-3 mt-3"
              >
                <p className="hover:bg-[#DADADA] p-3">For Teachers</p>
              </Link>{" "}
            </div>
          )}
          <p
            className="flex items-center relative"
            onMouseEnter={() => setHidden(false)}
          >
            <span className="link text-[#121212] hover:text-[#191970] hover:font-bold cursor-pointer focus:text-[#191970] font-bold">
              Use Cases
            </span>
            <span>
              <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.08789 6.00098L8.08789 10.001L12.0879 6.00098"
                  stroke="black"
                  stroke-width="1.33333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </p>
          <Link
            to="/faq"
            className="link text-[#121212] hover:text-[#191970] hover:font-bold cursor-pointer focus:text-[#191970] font-bold"
          >
            <p>FAQs</p>
          </Link>
          <Link
            to="/about"
            className="link text-[#121212] hover:text-[#191970] hover:font-bold cursor-pointer focus:text-[#191970] font-bold"
          >
            <p>About Us</p>
          </Link>
        </div>
        <div className="flex items-center text-[15px] font-[500]">
          {/* <div className="flex border-[1px] border-[#191970] rounded-[9px] items-center  text-[#191970] p-2 px-6">
          <span>Get Started</span>
          <img src={chev_down} alt="" className="ml-1" />
        </div> */}
          <a href="/#joinUs">
            <div className="bg-[#191970] rounded-[9px] cursor-pointer text-white p-2 px-4 ml-4">
              Join the waitlist
            </div>
          </a>
        </div>
      </div>

      <div className="w-full flex lg:hidden  justify-between items-center">
        <div className=" h-[25px] lg:h-[32px]">
          <Link to="/">
            <img
              src={Logo}
              alt="logo"
              className="object-contain w-full h-full"
            />
          </Link>
        </div>
        <img
          src={!active ? Ham : Close}
          alt="hamburger-menu"
          className=""
          onClick={() => setActive(!active)}
        />
      </div>
      {active ? (
        <VisibilitySensor partialVisibility>
          {({ isVisible }) => (
            <Spring
              delay={0}
              to={{
                overflow: "hidden",
                height: isVisible ? "900px" : "0px",
                zIndex: "10",
                transform: isVisible ? "translateX(0px)" : "translateX(-80%)",
              }}
            >
              {(props) => (
                <div
                  className="lg:hidden fixed w-11/12 z-30 px-5  py-10 bg-white top-0 left-0 "
                  style={{ ...props, backgroundColor: "#F8F8F8" }}
                >
                  <div className="w-full bg-white py-4 px-4 rounded-[1000px] flex lg:hidden  justify-between items-center">
                    <div className=" h-[25px] lg:h-[32px]">
                      <Link to="/">
                        <img
                          src={Logo}
                          alt="logo"
                          className="object-contain w-full h-full"
                        />
                      </Link>
                    </div>
                    <img
                      src={!active ? Ham : Close}
                      alt="hamburger-menu"
                      className=""
                      onClick={() => setActive(!active)}
                    />
                  </div>
                  <ul className="text-left px-5 w-full">
                    <li className="nav-link cursor-pointer py-4 text-[16px]">
                      <p
                        className="flex items-center relative"
                        onClick={() => setHidden(!hidden)}
                      >
                        <span className="link text-[#121212] hover:text-[#191970] hover:font-bold cursor-pointer focus:text-[#191970] font-bold">
                          Use Cases
                        </span>
                        <span>
                          <svg
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.08789 6.00098L8.08789 10.001L12.0879 6.00098"
                              stroke="black"
                              stroke-width="1.33333"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                      </p>
                    </li>

                    {!hidden && (
                      <div className="rounded-[8px] transition-container border-[0.3px] border-[#191970]  w-full  ">
                        <Link
                          to="/schools"
                          className="link text-[#121212] hover:text-[#191970] hover:bg-[#DADADA] hover:font-bold cursor-pointer focus:text-[#191970] font-bold py-1"
                        >
                          <p className="hover:bg-[#DADADA] p-2">For Schools</p>
                        </Link>
                        <Link
                          to="/teachers"
                          className="link text-[#121212] hover:text-[#191970] hover:font-bold cursor-pointer focus:text-[#191970] font-bold py-1"
                        >
                          <p className="hover:bg-[#DADADA] p-2">For Teachers</p>
                        </Link>{" "}
                      </div>
                    )}
                    <li className="nav-link cursor-pointer py-4 text-[16px]">
                      <Link
                        to="/faq"
                        className="link text-[#121212] hover:text-[#191970] hover:font-bold cursor-pointer focus:text-[#191970] font-bold"
                      >
                        <p>FAQs</p>
                      </Link>{" "}
                    </li>
                    <li className="nav-link cursor-pointer py-4 text-[16px]">
                      <Link
                        to="/about"
                        className="link text-[#121212] hover:text-[#191970] hover:font-bold cursor-pointer focus:text-[#191970] font-bold"
                      >
                        <p>About Us</p>
                      </Link>
                    </li>
                    <li className=" cursor-pointer py-4 text-xl w-full mx-auto">
                      <div className="flex items-center text-[16px] font-[500] w-full">
                        <a href="/#joinUs" className="w-full">
                          <div className="bg-[#191970] rounded-[9px] cursor-pointer text-white p-2 px-6 w-full text-center">
                            Join the waitlist
                          </div>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              )}
            </Spring>
          )}
        </VisibilitySensor>
      ) : (
        <></>
      )}
    </div>
  );
};
export default Header;
