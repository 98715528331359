/** @format */

import React,{useEffect} from "react";
import Footer from "./Footer";

const Main = ({ App }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="bg-[#F4F4F4]">
      {App}
      <Footer />
    </div>
  );
};
export default Main;
