/** @format */

import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { NigerianStates } from "../data/data";

const JoinUs = ({ formType, onClick }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [locationData, setLocationData] = useState(null);
  window.addEventListener("DOMContentLoaded", (event) => {
    const hash = "#joinUs";
    if (hash) {
      const targetElement = document.querySelector(hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  });
  const style = {
    active: "bg-[#fff] text-[#121212] w-1/2 rounded-[98px] p-3  lg:p-5",
    inactive: " text-[#F8F8F8] w-1/2 rounded-[98px] p-3 lg:p-5",
  };

  const handleSubmit = (values) => {
    setIsLoading(true);
    emailjs
      .send(
        "service_z6jtf7v",
        "template_ux9nq2v",
        {
          from_name: `${values.fName} ${values.lName}`,
          to_name: "Rotacude",
          message: JSON.stringify(values),
        },

        "1oTqWab3hhgnHCyVr"
        // access_token: "NZEMqyeliCGqe7ksbC3I_",
      )
      .then(
        (response) => {
          setIsLoading(false);
          formik1.resetForm();
          toast.success("Success: You have joined the waitlist");
        },
        (error) => {
          setIsLoading(false);

          console.log("Got here");
          toast.error("Something went wrong");
        }
      );
  };

  const handleSubmit2 = (values) => {
    console.log(values);
    setLoading(true);
    emailjs
      .send(
        "service_z6jtf7v",
        "template_u99gxtf",
        {
          from_name: values.school_name,
          to_name: "Rotacude",
          message: JSON.stringify(values),
        },

        "1oTqWab3hhgnHCyVr"
      )
      .then(
        (response) => {
          console.log(response);
          setLoading(false); // Your EmailJS logic here
          formik2.resetForm();

          toast.success("Success: You have joined the waitlist");
        },
        (error) => {
          setLoading(false); // Your EmailJS logic here

          console.log("error");
          toast.error("Something went wrong");
        }
      );
  };

  const formik1 = useFormik({
    initialValues: {
      fName: "",
      lName: "",
      email: "",
      phone: "",
    },
    validationSchema: Yup.object({
      // link: Yup.string().required("link is required"),
      fName: Yup.string().required("First name is required"),
      lName: Yup.string().required("Last name is required"),
      email: Yup.string().required("Email is required"),
      phone: Yup.string().required("Phone number is required"),
    }),

    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const formik2 = useFormik({
    initialValues: {
      school_name: "",
      email: "",
      location: "",
      phone: "",
    },
    validationSchema: Yup.object({
      // link: Yup.string().required("link is required"),
      school_name: Yup.string().required("School name is required"),
      location: Yup.string().required("Location is required"),
      email: Yup.string().required("Email is required"),
      phone: Yup.string().required("Phone number is required"),
    }),

    onSubmit: (values) => {
      handleSubmit2(values);
    },
  });

  // useEffect(() => {
  //   fetch("https://nigeria-states-towns-lga.onrender.com/api/states")
  //     .then((response) => {
  //       console.log(response);
  //       return response.json();
  //     })
  //     .then((data) => {
  //       // Extract and process the list of states from the response data
  //       const nigeriaStates = data?.map((state) => state?.name);
  //       setLocationData(nigeriaStates);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching Nigeria states:", error);
  //     });
  // }, []);
  return (
    <div className="bg-[#111111] pt-20 pb-20">
      <div className="border-[1px] border-[#F4F4F4] rounded-[98px] flex items-center text-center w-5/6 lg:w-1/2 mx-auto cursor-pointer">
        {["For Teachers", "For Schools"].map((item, index) => (
          <div
            className={formType === index ? style.active : style.inactive}
            onClick={() => onClick(index)}
          >
            {item}
          </div>
        ))}
      </div>

      <div className="w-11/12  lg:w-10/12 lg:flex flex-wrap lg:flex-nowrap items-center justify-center lg:justify-between mt-10 lg:mt-24 mx-auto">
        <div className="lg:w-1/2">
          <h1 className="w-full lg:w-full  text-[32px] lg:text-[80px] font-[700] text-[#F4F4F4] leading-[38px] lg:leading-[92px] ">
            {formType === 0
              ? "Join our growing network of talents."
              : "Tap into our growing pool of talented teachers"}
          </h1>
        </div>

        <div className="w-full mx-auto lg:w-3/6 mt-5 lg:mt-0">
          {formType === 0 ? (
            <form
              className="bg-[#F8F8F8] rounded-[16px] lg:rounded-[30px] p-6 lg:p-10 w-full mx-auto lg:w-full "
              onSubmit={formik1.handleSubmit}
            >
              <div className="flex items-center mb-5">
                <div className="w-full ">
                  <h6 className="text-[#18181B] font-bold text-[16px]">
                    First name
                  </h6>
                  <input
                    type="text"
                    name="fName"
                    ID="fName"
                    className="border-[1px] border-[#D4D4D8] rounded-[10px] text-[#71717A] text-[16px] font-[500] p-3.5 px-4 mt-2 outline-none w-full "
                    placeholder="Your first name"
                    onChange={formik1.handleChange}
                    onBlur={formik1.handleBlur}
                    value={formik1.values.fName}
                  />
                  {formik1.touched.fName && formik1.errors.fName ? (
                    <div className="text-red-400">{formik1.errors.fName}</div>
                  ) : null}
                </div>
              </div>
              <div className="flex items-center mb-5">
                <div className="w-full">
                  <h6 className="text-[#18181B]  font-bold text-[16px]  ">
                    Last name{" "}
                  </h6>
                  <input
                    type="text"
                    name="lName"
                    id="lName"
                    className="border-[1px] border-[#D4D4D8] rounded-[10px] text-[#71717A] text-[16px] font-[500] p-3.5 px-4 mt-2 outline-none w-full"
                    placeholder="Your last name"
                    onChange={formik1.handleChange}
                    onBlur={formik1.handleBlur}
                    value={formik1.values.lName}
                  />
                  {formik1.touched.lName && formik1.errors.lName ? (
                    <div className="text-red-400">{formik1.errors.lName}</div>
                  ) : null}
                </div>
              </div>
              <div className="w-full mb-5">
                <h6 className="text-[#18181B] font-[700] font-bold text-[16px]  ">
                  Email{" "}
                </h6>
                <input
                  type="text"
                  name="email"
                  id="email"
                  className="border-[1px] border-[#D4D4D8] rounded-[10px] text-[#71717A] text-[16px] font-[500] p-3.5 px-4 mt-2 w-full outline-none"
                  placeholder="your@email.com"
                  onChange={formik1.handleChange}
                  onBlur={formik1.handleBlur}
                  value={formik1.values.email}
                />
                {formik1.touched.email && formik1.errors.email ? (
                  <div className="text-red-400">{formik1.errors.email}</div>
                ) : null}
              </div>
              <div className="w-full mb-5">
                <h6 className="text-[#18181B] font-[700] font-bold text-[16px]  ">
                  Phone number{" "}
                </h6>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  className="border-[1px] border-[#D4D4D8] rounded-[10px] text-[#71717A] text-[16px] font-[500] p-3.5 px-4 mt-2 w-full outline-none"
                  placeholder="+234 ********"
                  onChange={formik1.handleChange}
                  onBlur={formik1.handleBlur}
                  value={formik1.values.phone}
                />
                {formik1.touched.phone && formik1.errors.phone ? (
                  <div className="text-red-400">{formik1.errors.phone}</div>
                ) : null}
              </div>

              {/* <div className="w-full mb-5">
              <h1   className="text-[#18181B] font-[700] font-bold text-[16px]  ">
                Message{" "}
              </h1>  
              <br />
              <textarea
                className="border-[1px] border-[#D4D4D8] rounded-[10px] text-[#71717A] text-[16px] font-[500] p-2 px-4 mt-2 w-full h-[229px] resize-none outline-none"
                placeholder="Write your message here..."
              />
            </div> */}
              <button
                className="flex items-center bg-[#18181B] rounded-[10px] w-full p-3.5 justify-center text-[18px] font-[600] text-[#fff]"
                type="submit"
              >
                {isLoading ? (
                  <ClipLoader color="#fff" size={25} loading />
                ) : (
                  <>
                    {" "}
                    <p>Send message</p>
                    <svg
                      width="16"
                      height="14"
                      viewBox="0 0 16 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-1"
                    >
                      <path
                        d="M15.5383 7.6633L9.91328 13.2883C9.73716 13.4644 9.49829 13.5634 9.24922 13.5634C9.00015 13.5634 8.76128 13.4644 8.58516 13.2883C8.40904 13.1122 8.31009 12.8733 8.31009 12.6242C8.31009 12.3752 8.40904 12.1363 8.58516 11.9602L12.6094 7.93752H1.125C0.87636 7.93752 0.637903 7.83874 0.462087 7.66293C0.286272 7.48711 0.1875 7.24866 0.1875 7.00002C0.1875 6.75137 0.286272 6.51292 0.462087 6.3371C0.637903 6.16129 0.87636 6.06252 1.125 6.06252H12.6094L8.58672 2.03751C8.4106 1.86139 8.31166 1.62252 8.31166 1.37345C8.31166 1.12438 8.4106 0.88551 8.58672 0.70939C8.76284 0.533269 9.00171 0.434326 9.25078 0.434326C9.49985 0.434326 9.73872 0.533269 9.91484 0.70939L15.5398 6.33439C15.6273 6.4216 15.6966 6.52523 15.7438 6.63931C15.7911 6.75339 15.8153 6.87569 15.8152 6.99917C15.815 7.12265 15.7905 7.24489 15.743 7.35886C15.6955 7.47283 15.6259 7.57629 15.5383 7.6633Z"
                        fill="white"
                      />
                    </svg>
                  </>
                )}
              </button>
            </form>
          ) : (
            <form
              className="bg-[#F8F8F8] rounded-[16px] lg:rounded-[30px] p-6 lg:p-10 w-full mx-auto lg:w-full "
              onSubmit={formik2.handleSubmit}
            >
              <div className="flex items-center mb-5">
                <div className="w-full ">
                  <h6 className="text-[#18181B] font-[700] font-bold text-[16px]  ">
                    School name{" "}
                  </h6>
                  <input
                    type="text"
                    name="school_name"
                    onChange={formik2.handleChange}
                    onBlur={formik2.handleBlur}
                    value={formik2.values.school_name}
                    className="border-[1px] border-[#D4D4D8] rounded-[10px] text-[#71717A] text-[16px] font-[500] p-3.5 px-4 mt-2 outline-none w-full "
                    placeholder="Enter your school's name"
                  />
                  {formik2.touched.school_name && formik2.errors.school_name ? (
                    <div className="text-red-400">
                      {formik2.errors.school_name}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="flex items-center mb-5">
                <div className="w-full">
                  <h6 className="text-[#18181B] font-[700] font-bold text-[16px]  ">
                    Email{" "}
                  </h6>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    onChange={formik2.handleChange}
                    onBlur={formik2.handleBlur}
                    value={formik2.values.email}
                    className="border-[1px] border-[#D4D4D8] rounded-[10px] text-[#71717A] text-[16px] font-[500] p-3.5 px-4 mt-2 outline-none w-full"
                    placeholder="your@email.com"
                  />
                  {formik2.touched.email && formik2.errors.email ? (
                    <div className="text-red-400">{formik2.errors.email}</div>
                  ) : null}
                </div>
              </div>

              <div className="w-full mb-5 relative">
                <h6 className="text-[#18181B] font-[700] font-bold text-[16px]  ">
                  Location{" "}
                </h6>
                <select
                  type="location"
                  id="location"
                  className=" cursor-pointer border-[1px] border-[#D4D4D8] appearance-none rounded-[10px] text-[#71717A] text-[16px] font-[500] p-3.5 px-4  mt-2 w-full outline-none"
                  placeholder="location"
                  onChange={formik2.handleChange}
                  onBlur={formik2.handleBlur}
                  value={formik2.values.location}
                >
                  <option value="" readOnly disabled selected className="">
                    Select State
                  </option>
                  {NigerianStates &&
                    NigerianStates?.map((x, id) => (
                      <option key={id} value={x}>
                        {x}
                      </option>
                    ))}
                </select>
                <svg
                  width="10"
                  height="5"
                  viewBox="0 0 10 5"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={`absolute ${
                    formik2.errors.location ? "bottom-12" : "bottom-6"
                  }  right-4`}
                >
                  <path d="M0 0L5 5L10 0H0Z" fill="black" />
                </svg>{" "}
                {formik2.touched.location && formik2.errors.location ? (
                  <div className="text-red-400">{formik2.errors.location}</div>
                ) : null}
              </div>
              <div className="w-full mb-5">
                <h6 className="text-[#18181B] font-[700] font-bold text-[16px]">
                  Phone number{" "}
                </h6>
                <input
                  type="phone"
                  id="phone"
                  name="phone"
                  className="border-[1px] border-[#D4D4D8] rounded-[10px] text-[#71717A] text-[16px] font-[500] p-3.5 px-4 mt-2 w-full outline-none"
                  placeholder="+234 **********"
                  onChange={formik2.handleChange}
                  onBlur={formik2.handleBlur}
                  value={formik2.values.phone}
                />
                {formik2.touched.phone && formik2.errors.phone ? (
                  <div className="text-red-400">{formik2.errors.phone}</div>
                ) : null}
              </div>

              {/* <div className="w-full mb-5">
              <h1   className="text-[#18181B] font-[700] font-bold text-[16px]  ">
                Message{" "}
              </h1>  
              <br />
              <textarea
                className="border-[1px] border-[#D4D4D8] rounded-[10px] text-[#71717A] text-[16px] font-[500] p-2 px-4 mt-2 w-full h-[229px] resize-none outline-none"
                placeholder="Write your message here..."
              />
            </div> */}
              <button
                className="flex items-center bg-[#18181B] rounded-[10px] w-full p-3.5 justify-center text-[18px] font-[600] text-[#fff]"
                type="submit"
              >
                {loading ? (
                  <ClipLoader color="#fff" size={25} loading />
                ) : (
                  <>
                    {" "}
                    <p>Send message</p>
                    <svg
                      width="16"
                      height="14"
                      viewBox="0 0 16 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-1"
                    >
                      <path
                        d="M15.5383 7.6633L9.91328 13.2883C9.73716 13.4644 9.49829 13.5634 9.24922 13.5634C9.00015 13.5634 8.76128 13.4644 8.58516 13.2883C8.40904 13.1122 8.31009 12.8733 8.31009 12.6242C8.31009 12.3752 8.40904 12.1363 8.58516 11.9602L12.6094 7.93752H1.125C0.87636 7.93752 0.637903 7.83874 0.462087 7.66293C0.286272 7.48711 0.1875 7.24866 0.1875 7.00002C0.1875 6.75137 0.286272 6.51292 0.462087 6.3371C0.637903 6.16129 0.87636 6.06252 1.125 6.06252H12.6094L8.58672 2.03751C8.4106 1.86139 8.31166 1.62252 8.31166 1.37345C8.31166 1.12438 8.4106 0.88551 8.58672 0.70939C8.76284 0.533269 9.00171 0.434326 9.25078 0.434326C9.49985 0.434326 9.73872 0.533269 9.91484 0.70939L15.5398 6.33439C15.6273 6.4216 15.6966 6.52523 15.7438 6.63931C15.7911 6.75339 15.8153 6.87569 15.8152 6.99917C15.815 7.12265 15.7905 7.24489 15.743 7.35886C15.6955 7.47283 15.6259 7.57629 15.5383 7.6633Z"
                        fill="white"
                      />
                    </svg>
                  </>
                )}
              </button>
            </form>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default JoinUs;
