/** @format */

import React, { useState, useEffect } from "react";
import Header from "../containers/Header";
import { FaqData } from "../data/data";
import Main from "../containers/Main";
import JoinUs from "../containers/JoinUs";
const Faq = () => {
  const [active, setActive] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [form, setForm] = useState(0);

  const App = (
    <div className="bg-[#F4F4F4] flex flex-col justify-center text-cent w-full pt-10 ">
      <div className="w-11/12 lg:w-3/4 mx-auto text-center">
        <Header />
      </div>
      <h1 className="text-[24px] lg:text-[48px] font-700 text-[#121212] my-8 lg:my-12 text-center px-10 lg:px-10">
        Frequently Asked Questions
      </h1>

      <div className="rounded-[16px] w-11/12 lg:w-3/4 bg-[#FFFFFF] mx-auto mb-5 lg:mb-10 lg:p-3">
        {FaqData.map((faq, index) => (
          <div className="border-b-[1px] border-[#EEEEEE] p-5" key={index}>
            <div className="flex items-center justify-between my-4">
              <h2 className="text-[20px] text-[#000] font-[700] w-4/5 lg:w-full">
                {faq.title}{" "}
              </h2>

              {selectedItem === index && active === true ? (
                <svg
                  width="14"
                  height="2"
                  viewBox="0 0 14 2"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="cursor-pointer w-[14px] h-[14px]"
                  onClick={() => {
                    setActive(true);
                  }}
                >
                  <path d="M14 2H0V0H14V2Z" fill="black" />
                </svg>
              ) : (
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="cursor-pointer w-[14px] h-[14px]"
                  onClick={() => {
                    setSelectedItem(index);
                    setActive(true);
                  }}
                >
                  <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="black" />
                </svg>
              )}
            </div>
            {selectedItem === index && active === true && (
              <p className="text-[16px] lg:text-[20px] font-[400]  text-[#636363]">
                {faq.desc}
              </p>
            )}
          </div>
        ))}
      </div>
      <div id="joinUs">
        <JoinUs formType={form} onClick={(prop) => setForm(prop)} />
      </div>
    </div>
  );
  return <Main App={App} />;
};

export default Faq;
