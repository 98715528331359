/** @format */

import React, { useState } from "react";
import Header from "../containers/Header";
import Main from "../containers/Main";
import JoinUs from "../containers/JoinUs";

const About = () => {
  const [form, setForm] = useState(0);

  const App = (
    <div className="w-full">
      <div className="hidden lg:flex flex-col about_us_header py-10">
        <div className="w-11/12 lg:w-3/4 mx-auto text-center">
          <Header />
          <div>
            <h1
              style={{
                fontWeight: "700",
                color: "#fff",
              }}
              className="my-10 text-[26px] lg:text-[64px] leading-[32px] lg:leading-[72px]"
            >
              We are committed to empowering educators{" "}
            </h1>
          </div>
        </div>
      </div>
      <div className=" lg:hidden flex-col about_us_header_sm py-10">
        <div className="w-11/12 lg:w-3/4 mx-auto text-center">
          <Header />
          <div>
            <h1
              style={{
                fontWeight: "700",
                color: "#fff",
              }}
              className="my-10 text-[26px] lg:text-[64px] leading-[32px] lg:leading-[72px]"
            >
              We are committed to empowering educators{" "}
            </h1>
          </div>
        </div>
      </div>

      <div className="w-full lg:w-2/3 mx-auto text-center pt-10 pb-10 lg:pb-40">
        <div
          style={{
            fontWeight: "700",
            color: "#121212",
            lineHeight: "40px",
          }}
          className="text-[16px] lg:text-[24px] px-3 lg:px-0"
        >
          <div className="text-left lg:text-center flex justify-center lg:mb-1">
            {" "}
            At Rotacude, we are passionate about revolutionizing the hiring
            process in the education sector. Our team consists of experienced
            educators, technologists, and industry experts dedicated to creating
            innovative solutions that benefit both schools and teachers.
          </div>
          <p className="text-left lg:text-center flex justify-center lg:mb-1">
            {" "}
            Driven by a commitment to excellence, we leverage cutting-edge
            technology to simplify recruitment and placement, making it easier
            for schools to find qualified candidates and for teachers to secure
            rewarding positions. Our platform fosters transparency, efficiency,
            and collaboration, ultimately enhancing the overall educational
            experience.{" "}
          </p>
          <p className="text-left lg:text-center flex justify-center ">
            With a deep understanding of the challenges faced by schools and
            teachers alike, we continuously strive to improve and refine our
            platform to meet the evolving needs of the education community.
            Whether you're a school administrator seeking to fill vacancies or a
            teacher looking for new opportunities, Rotacude is here to support
            you every step of the way.
          </p>
        </div>
      </div>
      <div id="joinUs">
        <JoinUs formType={form} onClick={(prop) => setForm(prop)} />
      </div>
    </div>
  );
  return <Main App={App} />;
};

export default About;
