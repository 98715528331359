/** @format */

import React, { useState } from "react";
import Header from "../containers/Header";
import Main from "../containers/Main";
import Features from "../components/Features";
import { Pool, Match, folderOpen } from "../assets/icons";
import JoinUs from "../containers/JoinUs";

const Teachers = () => {
  const [form, setForm] = useState(0);

  const SchoolData = [
    {
      img: Pool,
      text: "Access to a wider pool of job opportunities.",
    },
    {
      img: Match,
      text: "Efficient matching with compatible schools.",
    },
    {
      img: folderOpen,
      text: "Exposure to professional development resources.",
    },
  ];
  const App = (
    <div className="w-full pt-10">
      <div className="w-11/12 lg:w-3/4 mx-auto text-center">
        <Header />
      </div>
      <div className="w-full lg:w-1/2 mx-auto flex flex-col justify-center my-5 text-center">
        <span className="font-[700] px-4 lg:px-4 py-1 rounded-[100px] bg-[#121212] text-[12px] lg:text-[18px] text-[#FFF]  mx-auto my-5 lg:my-10">
          TEACHERS{" "}
        </span>

        <h1
          style={{
            fontWeight: "700",
            color: "#111122",
          }}
          className="my-2 lg:my-5 text-[24px] lg:text-[64px] leading-[30px] lg:leading-[72px]"
        >
          Made for Teachers{" "}
        </h1>

        <p
          style={{
            fontWeight: "700",
            color: "#6C6C6C",
            lineHeight: "33.6px",
          }}
          className="text-center w-5/6 lg:w-2/3 mx-auto text-[16px] lg:text-[24px] leading-[22px] lg:leading-[33.6px] font-[400] mb-3 lg:mb-0"
        >
          Start a rewarding teaching career and get connected with the very best
          schools.
        </p>

        <div className="bg-[#191970] rounded-[9px] cursor-pointer text-white p-2 lg:p-2 px-4 mx-1 flex items-center w-11/12 lg:w-1/5 mx-auto my-4 lg:my-7">
          <a href="#joinUs" className="w-full flex items-center justify-center">
            <span className="mr-1 text-[15px]"> Get in touch</span>
            <svg
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.5 0L5.4425 1.0575L9.6275 5.25H0.5V6.75H9.6275L5.4425 10.9425L6.5 12L12.5 6L6.5 0Z"
                fill="white"
              />
            </svg>
          </a>
        </div>
      </div>
      <div>
        <Features data={SchoolData} />
      </div>

      <div>
        <div className="w-full rounded-[24px]  bg-white p-5 lg:w-3/4 mx-auto my-10">
          <div className="wycdtch rounded-[8px] text-center hidden lg:flex items-center justify-center">
            <h1
              style={{
                fontWeight: "700",
                color: "#F4F4F4",
                lineHeight: "72px",
              }}
              className="my-5 text-[20px] lg:text-[40px]"
            >
              What you can do on Rotacude{" "}
            </h1>{" "}
          </div>

          <div className="wycdtch_sm rounded-[8px] text-center flex lg:hidden items-center justify-center">
            <h1
              style={{
                fontWeight: "700",
                color: "#F4F4F4",
              }}
              className="my-5 px-10 text-[20px] lg:text-[40px]"
            >
              What you can do on Rotacude{" "}
            </h1>{" "}
          </div>

          <div className="mx-6 my-10">
            <h1
              style={{
                fontWeight: "700",
                color: "#121212",
                lineHeight: "25.08px",
              }}
              className="my-2 lg:my-5 text-[20px] lg:text-[24px] "
            >
              Create a profile{" "}
            </h1>{" "}
            <p className="flex items-start text-[#6C6C6C] mb-2">
              <span className="w-1 h-1 rounded-full bg-[#6C6C6C] mr-2 mt-3"></span>
              <span>Qualifications (degrees, certifications)</span>
            </p>
            <p className="flex items-start text-[#6C6C6C] mb-2">
              <span className="w-1 h-1 rounded-full bg-[#6C6C6C] mr-2 mt-3"></span>
              <span>Experience (teaching history, skills)</span>
            </p>
            <p className="flex items-center text-[#6C6C6C]">
              <span className="w-1 h-1 rounded-full bg-[#6C6C6C] mr-2 mb-2"></span>
              <span>Specialties (subject areas, grade levels)</span>
            </p>
            <p className="flex items-start text-[#6C6C6C] mb-2">
              <span className="w-1 h-1 rounded-full bg-[#6C6C6C] mr-2 mt-3"></span>
              <span>Preferences (location, teaching environment)</span>
            </p>
          </div>

          <div className="mx-6 my-10">
            <h1
              style={{
                fontWeight: "700",
                color: "#121212",
                lineHeight: "25.08px",
              }}
              className="my-2 lg:my-5 text-[20px] lg:text-[24px] "
            >
              Access Resources{" "}
            </h1>{" "}
            <p className="flex items-start text-[#6C6C6C] mb-2">
              <span className="w-1 h-1 rounded-full bg-[#6C6C6C] mr-2 mt-3"></span>
              <span>Lesson plans and teaching materials</span>
            </p>
            <p className="flex items-start text-[#6C6C6C] mb-2">
              <span className="w-1 h-1 rounded-full bg-[#6C6C6C] mr-2 mt-3"></span>
              <span>Online courses and training opportunities.</span>
            </p>
            <p className="flex items-start text-[#6C6C6C] mb-2">
              <span className="w-1 h-1 rounded-full bg-[#6C6C6C] mr-2 mt-3"></span>
              <span>Specialties (subject areas, grade levels)</span>
            </p>
            <p className="flex items-start text-[#6C6C6C] mb-2">
              <span className="w-1 h-1 rounded-full bg-[#6C6C6C] mr-2 mt-3"></span>
              <span>Links to professional organizations and communities.</span>
            </p>
          </div>
        </div>
        <div id="joinUs">
          <JoinUs formType={form} onClick={(prop) => setForm(prop)} />
        </div>
      </div>
    </div>
  );
  return <Main App={App} />;
};

export default Teachers;
