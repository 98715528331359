/** @format */
import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import LandingPage from "./pages/LandingPage";
import Faq from "./pages/Faq";
import About from "./pages/About";
import Schools from "./pages/Schools";
import Teachers from "./pages/Teachers";
const App = () => {

  return (
    <div className="">
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />}></Route>
          <Route path="/faq" element={<Faq />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/schools" element={<Schools />}></Route>
          <Route path="/teachers" element={<Teachers />}></Route>
        </Routes>
      </Router>
    </div>
  );
};

export default App;
