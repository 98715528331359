/** @format */

import React from "react";

const Features = ({ data }) => {
  return (
    <div className="border border-[1px] border-l-0 border-r-0 py-3 lg:py-10 lg:px-30 bg-white w-full">
      <div className="w-full lg:w-11/12 mx-auto flex flex-wrap items-center mx-auto justify-centerlg:justify-evenly ">
        {data.map((item, index) => (
          <div
            className={`flex items-center w-full ${
              index == 2 ? "lg:w-1/3" : "lg:w-1/4"
            } my-5 lg:my-0 mx-8`}
          >
            <img
              src={item.img}
              alt=""
              className="mr-4 w-[32px] h-[32px] lg:w-[48px] lg:h-[48px]"
            />
            <p className="text-[16px] lg:text-[18px] font-[400] text-[#6C6C6C] ">
              {item.text}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features;
