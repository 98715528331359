/** @format */

import React, { useState, useEffect } from "react";
import Hero from "../containers/Hero";
import Schools from "../components/Schools";
import Benefits from "../containers/Benefits";
import JoinUs from "../containers/JoinUs";
import Why from "../containers/Why";
import Main from "../containers/Main";
import { useLocation } from "react-router-dom";

const LandingPage = () => {
  const [form, setForm] = useState(0);
  const location = useLocation();

  useEffect(() => {
    // Scroll to the target section when the component mounts
    const scrollToSection = () => {
      const sectionId = location.hash.substring(1);
      const targetElement = document.getElementById(sectionId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    };

    scrollToSection(); // Scroll on initial load

    // Optionally, scroll when the hash changes (e.g., navigation within the same page)
    const handleHashChange = () => {
      scrollToSection();
    };
    window.addEventListener("hashchange", handleHashChange);

    return () => {
      // Cleanup: Remove event listener when component unmounts
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [location.hash]); // Re-run effect when hash changes

  const App = (
    <div className="scroll " style={{ scrollBehavior: "smooth" }}>
      <Hero onClick={(prop) => setForm(prop)} />
      {/* <Schools /> */}
      <Benefits />
      <Why />
      <div id="joinUs">
        <JoinUs formType={form} onClick={(prop) => setForm(prop)} />
      </div>
    </div>
  );
  return <Main App={App} />;
};
export default LandingPage;
