/** @format */

import React from "react";
import { globe, work, network, driveeta, quality } from "../assets/icons/index";

const Why = () => {
  const Data = [
    {
      img: work,
      title: "Streamlining Hiring Processes",
      desc: "Rotacude cuts through school hiring chaos! Our platform simplifies job postings, applications, and interviews. We use a smart algorithm to match schools with perfect teachers, saving time and finding the best fit.",
    },
    {
      img: network,
      title: "Expanding Access to Opportunities",
      desc: "Rotacude connects teachers of all experience levels with various schools.  New graduates can find their first placement, while experienced educators can discover exciting new opportunities to grow their careers.",
    },
    {
      img: quality,
      title: "Facilitating Quality Placements",
      desc: "Rotacude helps schools find perfect-fit teachers by letting them list specific needs. Teachers can search for ideal positions that match their skills and goals, leading to happier teachers and stronger schools.",
    },
    {
      img: globe,
      title: "Promoting Collaboration and Community",
      desc: "Rotacude goes beyond connecting teachers with jobs. It fosters collaboration! Schools can share best practices, work together on projects, and build a supportive network. This strengthens teaching and ultimately benefits student learning.",
    },
    {
      img: driveeta,
      title: "Driving Educational Excellence",
      desc: "Rotacude connects schools and teachers for a better learning experience. We streamline hiring, expand opportunities, and ensure great placements, empowering all to thrive and ultimately benefitting students.",
    },
  ];
  return (
    <div className="bg-[#F8F8F8] px-5 lg:px-0 pt-10 pb-20">
      <div className="w-full lg:w-11/12 flex flex-col text-center justify-center mx-auto">
        <h1 className="text-[24px] lg:text-[52px] font-[700]">Why Rotacude?</h1>
        <div className="flex flex-wrap lg:flex-nowrap items-center justify-center lg:my-5">
          {Data.slice(0, 3).map((data, index) => (
            <div
              className="shadow-md rounded-[16px] bg-[#fff] flex flex-col text-center lg:h-[358px] lg:w-[395px] p-5 mx-3 mt-5 lg:mt-0"
              key={index}
            >
              <img
                src={data.img}
                alt={data.title}
                className="w-[32px] h-[32px] lg:w-[48px] lg:h-[48px] mx-auto mb-7"
              />
              <h1 className="text-[20px] text-[#18181B] font-[600] tracking-wide mb-5  ">
                {data.title}
              </h1>
              <p className="font-[400] text-[14px] text-[#71717A] tracking-wider">
                {data.desc}
              </p>
            </div>
          ))}
        </div>
        <div className="flex flex-wrap lg:flex-nowrap items-center justify-center lg:my-3 w-full ">
          {Data.slice(3, 6).map((data, index) => (
            <div
              className="shadow-md rounded-[16px] bg-[#fff] flex flex-col text-center mx-3 p-5 lg:py-7 px-7 mt-5 lg:mt-0"
              key={index}
            >
              <img
                src={data.img}
                alt={data.title}
                className="w-[32px] h-[32px] lg:w-[48px] lg:h-[48px] mx-auto mb-7"
              />
              <h1 className="text-[20px] text-[#18181B] font-[700] tracking-wide mb-5  ">
                {data.title}
              </h1>
              <p className="font-[400] text-[14px] text-[#71717A] tracking-wider">
                {data.desc}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Why;
